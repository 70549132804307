<template>
    <div class="heart-item" :class="{ highlight: highlight }" @click="onClickItem">
        <div class="count">
            <div class="count-all" :class="{ 'm-b-12': item.bonus }">
                <div class="upper">
                    <img class="small-heart" src="@/assets/images/red-heart.png" />
                    <div class="name f-medium m-l-8">{{ itemName }}</div>
                    <div class="highlight spoqa-f-medium m-l-12" v-if="highlight && newbie">최다 판매</div>
                </div>
            </div>
            <span class="count-calc f-regular" v-if="item.bonus" v-html="itemCalc" />
        </div>
        <div class="price c-primary" :class="{ newbie }">
            <div class="highlight spoqa-f-medium" v-if="highlight && !newbie">최다 판매</div>
            <div class="krw" v-if="!newbie">
                {{ !item.price ? item.description : `₩ ${item.price.toLocaleString()}` }}
            </div>
        </div>
    </div>
</template>
<script>
import { currency } from '@/filters'

export default {
    name: 'HeartItem',
    props: ['item', 'newbie'],
    mounted() {},
    computed: {
        highlight() {
            return this.item.price === 49800
        },
        itemName() {
            if (!this.item) return ''

            const heartCount = this.item.amount + this.item.bonus

            return currency(heartCount)
        },
        itemCalc() {
            const bonus = this.newbie ? '신규가입보너스' : '보너스'

            return `${this.item.amount}
                    <span class="spoqa-f-bold c-pink-deep"> + ${bonus} ${this.item.bonus}</span>
                    `
        },
    },
    methods: {
        onClickItem() {
            this.$emit('click', this.item)
        },
    },
}
</script>

<style lang="scss" scoped>
.heart-item {
    height: 100%;
    padding: 16px 20px 16px 16px;
    border-radius: 12px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05), 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    @include items-center;
    @include flex-between;

    &.highlight {
        border: 1px solid rgba($pink-deep, 0.3);
    }

    .badge {
        border-radius: 6px;
        font-size: 10px;
        color: white;
        margin-bottom: 6px;
        padding: 1px 7px;
        @include f-regular;
        @include center;

        span {
            @include flex-wrap;
            &:not(:last-child) {
                margin-right: 2px;
            }
        }
    }

    .count {
        flex: auto;

        .count-all {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .upper {
                display: flex;
                align-items: center;
                flex: auto;

                .highlight {
                    height: 22px;
                }
            }
            .krw {
                flex: none;
            }

            .small-heart {
                margin-top: 2px;
                max-width: 24px;
                height: 24px;
                padding: 5px 3px 4px 2px;
            }

            .name {
                //margin-left: 8px;
                //margin-bottom: 6px;
                font-size: 24px;
                color: black;
                @include spoqa-f-bold;
            }
        }

        .count-calc {
            font-size: 14px;
            color: $grey-09;
        }
    }

    .price {
        flex: none;
        font-size: 15px;
        color: $grey-08;
        width: fit-content;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        @include spoqa-f-regular;

        &.newbie {
            justify-content: normal;
        }
    }
    .highlight {
        color: white;
        background-image: linear-gradient(289deg, #ff70a4, $pink-deep);
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 6px;
        @include center;
    }
    &.no-badge {
        padding-top: 20px;
        padding-bottom: 16px;
    }
}
</style>
