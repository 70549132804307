<template>
    <div class="in-app-store">
        <StackRouterHeaderBar :title="$translate('IN_APP_STORE')" :showTitle="true" />
        <div class="contents">
            <div class="section heart-purchase">
                <div class="head flex-row items-center flex-between">
                    <div class="title f-medium" v-html="'하트 구매'" />
                    <div class="heart">
                        <span class="text">보유 하트</span>
                        <img class="small-heart" src="@/assets/images/red-heart.png" />
                        <span class="count c-pink-deep spoqa-f-bold">{{ myHeart }}</span>
                    </div>
                </div>
                <div class="heart-items">
                    <HeartItem
                        :newbie="newbie"
                        v-for="item in heartItems"
                        :key="item.id"
                        :item="item"
                        @click="onClickItem"
                    />
                </div>
                <p class="vat">* 모든 상품은 부가세 별도입니다.</p>
            </div>
            <div class="free-heart" v-if="!isFemale && [6, 7, 8, 9, 0].includes(this.$store.getters.me.id % 10)">
                <div class="title f-medium m-b-24" v-html="'무료 하트'" />
                <HeartItem v-for="item in freeHeartItems" :key="item.id" :item="item" @click="onClickFreeItem(item)" />
            </div>
        </div>
        <div class="footer">
            <div>
                <div class="title">결제 관련 문의</div>
                <div class="content">
                    <ul>
                        <li>
                            카카오톡 플러스친구 <span>"반쪽"</span>으로 문의해 주시면, 최대한 신속하게
                            도움드리겠습니다.<br />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeartItem from './components/HeartItem'
import productService from '@/services/product'

export default {
    name: 'InAppStorePage',
    components: {
        HeartItem,
    },
    props: ['from'],
    data: () => ({
        heartItems: null,
        freeHeartItems: null,
        selectedProduct: null,
        newbie: false,
    }),
    computed: {
        myHeart() {
            return (this.$store.getters.badges || {}).heart
        },
        gender() {
            return (this.$store.getters.me || {}).gender
        },
        isFemale() {
            return this.gender === 1
        },
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Enter_StorePage',
                    option: {
                        channel: this.from,
                        profile_status: this.$store.getters.profileStatus,
                    },
                },
            })

            try {
                const { heart } = await productService.all()
                this.heartItems = heart.filter(h => {
                    const defaultFilter = h.amount < 600 && h.amount > 15
                    const promotionFilter = h.target === 'all'

                    return (promotionFilter && defaultFilter) || h.id === 2
                })
                this.freeHeartItems = heart.filter(h => h.purchase === 'free')
            } catch (e) {}
        },
        onClickFreeItem(item) {
            this.$modal.custom({
                component: 'ModalFreeHeart',
                options: {
                    heartAmount: item.amount,
                },
            })
        },
        onClickItem(item) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Try_Purchase',
                    option: {
                        channel: this.from,
                        product: item.id,
                        position: item.position,
                        profile_status: this.$store.getters.profileStatus,
                    },
                },
            })
            this.$modal.custom({ component: 'ModalSelectPurchaseMethod' }).then(button => {
                switch (button) {
                    case 'purchaseInapp':
                        this.purchaseInapp(item)
                        break
                    case 'purchaseVirtualAccount':
                        this.purchaseVirtualAccount(item)
                        break
                }
            })
        },
        async purchaseVirtualAccount(item) {
            const gender = this.$store.getters.me.gender === 0 ? 'male' : 'female'
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Try_Purchase_by_virtual',
                    option: {
                        channel: this.from,
                        product: item.id,
                        position: item.position,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `purchase_${gender}`,
                    option: {
                        type: 'custom',
                        value: item.price,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendPurchaseEvents',
                value: {
                    gender: gender,
                    item: item,
                },
            })
            try {
                const data = await productService.createOrder(item.id, item)
                this.$stackRouter.push({
                    name: 'VirtualAccountPage',
                    props: { userVirtualAccount: data.user_virtual_account },
                })
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        purchaseInapp(item) {
            const gender = this.$store.getters.me.gender === 0 ? 'male' : 'female'
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Try_Purchase_by_inapp',
                    option: {
                        channel: this.from,
                        product: item.id,
                        position: item.position,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `purchase_${gender}`,
                    option: {
                        type: 'custom',
                        value: item.price,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendPurchaseEvents',
                value: {
                    gender: gender,
                    item: item,
                },
            })

            this.selectedProduct = item
            this.$store.dispatch('requestInAppPurchase', item)
        },
    },
}
</script>

<style lang="scss" scoped>
.in-app-store {
    .contents {
        padding: 36px 16px 0px 16px;
        margin-bottom: 48px;

        .section {
            .head {
                margin-bottom: 24px;
            }
            .desc {
                font-size: 14px;
            }
        }
        .title {
            font-size: 20px;
            color: black;
        }

        .heart-purchase {
            margin-bottom: 60px;
            .heart {
                display: flex;
                align-items: center;

                .text {
                    font-size: 14px;
                    color: $grey-08;
                }
                .small-heart {
                    width: 20px;
                    height: 20px;
                    padding: 4px 2px 3px 2px;
                    margin-left: 8px;
                    margin-right: 2px;
                    vertical-align: bottom;
                }
                .count {
                    font-size: 14px;
                }
            }

            .heart-items {
                display: grid;
                grid-gap: 8px;
                margin-bottom: 8px;

                .promotion-timer {
                    display: flex;
                    align-items: center;
                    padding: 10px 14px;
                    border-radius: 14px;
                    background-image: linear-gradient(to left, #ff70a4, #ff3d6b);

                    .timer {
                        display: flex;
                        align-items: center;
                        color: white;
                        background: #ff5c87;
                        width: fit-content;
                        padding: 5px 8px;
                        border-radius: 14px;
                        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
                        font-size: 14px;

                        i {
                            color: white;
                            margin-right: 6px;
                            font-size: 16px;
                        }
                    }
                }

                &.male {
                    grid-template-columns: repeat(3, 1fr);
                }

                &.female {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                }
            }

            .vat {
                margin-top: 19px;
                font-size: 12px;
                color: $grey-07;
            }
        }
    }

    .footer {
        width: 100%;
        padding: 24px 16px 32px;
        background-color: $grey-01;

        .title {
            color: $grey-09;
            margin-bottom: 8px;
            @include f-medium;
        }

        .content {
            color: $grey-07;
            font-size: 12px;
            line-height: 18px;

            span {
                @include f-bold;
            }
        }
    }
}

.super-privacy-purchase {
    //   margin-bottom: 48px;

    .super-privacy-banner {
        width: 100%;
        height: 154px;
        border-radius: 12px;
        padding-top: 25px;
        padding-bottom: 18px;
        background-image: linear-gradient(to bottom, $blue-facebook, #4f2eff 130%);

        .super-privacy-comment {
            // margin-top: 20px;
            @include f-regular;
            font-size: 14px;
            color: white;
            text-align: center;
            opacity: 0.8;
        }

        .super-privacy-mode {
            @include f-bold;
            font-size: 24px;
            height: 34px;
            color: white;
            text-align: center;
            margin-top: 1px;
            margin-bottom: 20px;
            padding: 5px 0;
        }

        .super-privacy-more {
            width: 124px;
            height: 36px;
            background-color: white;
            border-radius: 8px;
            margin-top: 18px;
            margin-bottom: 18px;
            text-align: center;
            margin: 0 30vw;
            padding: 0 20px;

            .text {
                @include f-medium;
                font-size: 13px;
                text-align: center;
                color: black;
                align-self: center;
            }

            .i-right {
                color: black;
                font-size: 13px;
                align-self: center;
                // background-color: black;
            }
        }
    }

    .vat {
        font-size: 11px;
        color: $grey-05;
    }
}
</style>
